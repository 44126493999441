.meet_orbix {
  height: auto;
  width: auto;
  margin-top: 12%;
}

.meet_orbix_header_part {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 12%;
  width: 100vw;
}

.meet_orbix_header_text {
  text-shadow: 3px 3px 6px rgb(194, 194, 194);
  font-size: 45px;
  font-weight: 800;
  margin-top: 5%;
}

.header_image_under_line {
  width: 5vw;
  position: absolute;
  top: 60%;
  left: 52%;
}

.header_image_under_line img {
  width: 80px;
  height: auto;
}

.meet_orbix_header_text_2 {
  font-size: 30px;
  margin-top: 0.8em;
}

.meet_orbix_uper_part {
  padding-top: 3%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
}

.meet_orbix_uper_text_part {
  width: 50vw;
  padding-left: 10%;
}

.artifical_text_1 {
  font-size: 40px;
  font-weight: 700;
  color: rgb(57, 149, 235);
}

.artifical_text_2 {
  width: 35vw;
  font-size: 40px;
  font-weight: 700;
}

.artifical_detail_text {
  width: 34vw;
  color: rgb(86, 86, 86);
}

.artifical_detail_text_2 {
  margin-top: 6%;
  width: 34vw;
  color: rgb(132, 135, 168);
}

.computer_rebort {
  margin-top: -8%;
}

.computer_rebort img {
  width: 130%;
  height: auto;
}

.header_image_under_line_2 {
  margin-top: -5.5%;
  margin-left: 24%;
}

.arrow_blox {
  margin-top: 20%;
}

.arror {
  color: rgb(66, 166, 216);
}

.arror_text {
  color: black;
  font-weight: 650;
}

.hi_sign {
  display: flex;
  width: 50vw;
  justify-content: flex-end;
}

.hi_sign img {
  /* width: 100%; */
}

.computer_rebort {
  width: 50vw;
}


@media screen and (max-width: 4000px) {
  .header_image_under_line {
    top: 71%;
    left: 51%;
  }

  .header_image_under_line_2 {
    margin-top: -2.5%;
    margin-left: 0;
  }
}

@media screen and (max-width: 2500px) {
  .header_image_under_line_2 {
    margin-top: -2.5%;
    margin-left: 0;
  }

  .arrow_blox {
    margin-top: 0%;
  }

  .header_image_under_line {
    top: 71%;
    left: 51%;
  }
}

@media screen and (min-width: 1709px) and (max-width: 1822px) {
  .header_image_under_line_2 {
    margin-top: -3.5%;
    margin-left: 0;
  }
}

@media screen and (min-width: 1521px) and (max-width: 1708px) {
  .header_image_under_line_2 {
    margin-top: -4.5%;
    margin-left: 0;
  }

  .arrow_blox {
    margin-top: 10%;
  }
}

@media screen and (min-width: 1368px) and (max-width: 1520px) {
  .meet_orbix {
    margin-top: 10%;
  }

  .header_image_under_line_2 {
    margin-left: 0;
  }

  .arrow_blox {
    margin-top: 5%;
  }
}

@media screen and (min-width: 1231px) and (max-width: 1367px) {
  .meet_orbix {
    margin-top: 10%;
  }

  .arrow_blox {
    margin-top: 5%;
  }

  .header_image_under_line_2 {
    /* margin-top: -6.5%; */
    margin-left: 0;
  }
}

@media screen and (min-width: 1026px) and (max-width: 1230px) {
  .hi_sign img {
    width: 100%;
  }

  .computer_rebort img {
    width: 115%;
    height: auto;
  }

  .header_image_under_line_2 {
    /* margin-top: -6.5%; */
    margin-left: 0;
  }

  .arrow_blox {
    margin: 5% 0;
  }

  .artifical_detail_text_2 {
    margin-top: 0;
    width: 38vw;
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 1025px) {
  .meet_orbix {
    margin-top: 15%;
  }

  .header_image_under_line_2 {
    margin-left: 0;
  }

  .arrow_blox {
    margin: 10% 0;
  }

  .header_image_under_line_2 img {
    width: 90%;
    height: auto;
  }

  .meet_orbix_header_text {
    margin-top: 5%;
  }

  .arror_text {
    font-size: 0.9rem;
  }

  .artifical_detail_text_2 {
    margin-top: 0;
    width: 38vw;
  }

  .artifical_text_2 {
    width: 38vw;
    font-size: 1.6rem;
  }
}

@media (max-width: 600px) {
  .meet_orbix {
    margin-top: 15%;
  }

  .meet_orbix_uper_part {
    flex-direction: column;
  }

  .meet_orbix_uper_text_part {
    padding-top: 4%;
    width: 98vw;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 0;
  }

  .artifical_detail_text {
    display: flex;
    width: 80vw;
    margin-top: 1em;
    text-align: justify;
  }

  .header_image_under_line_2 {
    margin-top: -7.5%;
    margin-left: 3%;
    width: 100vw;
  }

  .artifical_text_1 {
    font-size: 30px;
  }

  .artifical_text_2 {
    font-size: 30px;
  }

  .header_image_under_line_2 img {
    width: 60%;
    margin-top: 3%;
    margin-left: 8%;
  }

  .meet_orbix_header_text_2 {
    font-size: 20px;
  }

  .meet_orbix_header_text {
    margin-top: 5%;
  }

  .artifical_text_2 {
    width: 80vw;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .hi_sign {
    /* padding: 3%; */
    display: flex;
    width: 100vw;
    justify-content: flex-start;
  }

  .hi_sign img {
    /* width: 115%; */
  }

  .computer_rebort {
    width: 77vw;
    display: flex;
    justify-content: center;
  }

  .computer_rebort img {
    width: 130%;
    height: auto;
  }

  .artifical_detail_text_2 {
    text-align: justify;
    width: 80vw;
    margin-top: -1%;
  }

  .meet_orbix_uper_part_2 {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 400px) {

  .header_image_under_line {
    top: 71%;
    left: 51%;
  }

  .meet_orbix {
    margin-top: 41%;
  }

  .header_image_under_line_2 img {
    width: 70%;
    height: auto;
    margin-top: 3%;
    margin-left: 5%;
  }

  .meet_orbix_header_text {
    font-size: 2rem;
  }
}