.footer {
  /* background-image: url(../../Assets/Footer--background.png); */
  background-color: black;
  overflow: hidden;
  padding-bottom: 20px;
}

.Footer1 {
  height: auto;
  padding-top: 4%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
  color: white;
  padding-bottom: 4%;
  border-bottom: 1px solid gray;
}

.Footer_Text {
  width: 85vw;
}

.Footer_buttons {
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.playstore_button {
  margin: 2%;
  background-color: rgb(50, 139, 222);
  padding: 2%;
  padding-left: 4%;
  padding-right: 4%;
  border-radius: 10px;
}

.footer_text_detail {
  width: 32vw;
  margin-top: 2%;
  margin-bottom: 3%;
  color: rgb(223, 228, 231);
  font-size: 15px;
}

.footer_list {
  display: flex;
  width: 30vw;
}

.footer_list_line {
  margin-right: 9%;
  font-size: 20px;
  cursor: pointer;
}

.footer2 {
  height: auto;
  padding-top: 1%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
  color: white;
}

.Footer_icon {
  width: 30vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Footer_icon img {
  margin-left: 5%;
  height: auto;
}

.all_right_orbix {
  width: auto;
}

@media only screen and (max-width: 600px) {
  .footer {
    padding-bottom: 7%;
  }

  .Footer1 {
    flex-direction: column;
    width: 80vw;
    justify-content: flex-start;
  }

  .Footer_Text {
    width: 80vw;
  }

  .footer_text_detail {
    width: 80vw;
  }

  .footer_list {
    flex-direction: column;
  }

  .Footer_buttons {
    padding-top: 5%;
    width: 80vw;
  }

  .footer2 {
    flex-direction: column-reverse;
    width: 80vw;
  }

  .Footer_icon {
    width: 80vw;
  }

  .Footer_icon img {
    margin-bottom: 6%;
    margin-right: 9%;
    margin-left: 0px;
    height: auto;
  }

  .all_right_orbix {
    padding-left: 18%;
  }
}
