body,
html {
  overflow-x: hidden;
}

.model__container__content__form__content__input__select * {
  font-size: 14px;
  min-height: 30px;
}

.model__container__content__form__content__input__select .css-reqq1a-control {
  width: 100%;
  min-height: 45px;
  border: 1px solid #919eab;
  background-color: #919eab;
  border-radius: 8px;
  padding: 0em 0.3em;
  outline: none;
  box-shadow: none;
}
.model__container__content__form__content__input__select .css-j7ercm-control {
  width: 100%;
  min-height: 45px;
  border: 1px solid #919eab;
  background-color: #919eab;
  border-radius: 8px;
  padding: 0em 0.3em;
  outline: none;
  box-shadow: none;
}
