.selectPlanMainContainer {
  width: 100vw;
  min-height: 100vh;
  background: #f5f9ff;
  /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}

.selectPlanContainer {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.selectPlanHeadingContainer {
  color: #2b3674;
  margin-top: 40px;
  text-align: center;
  font-size: 38px;
  font-weight: 500;
  line-height: 32px;
  /* 84.211% */
  letter-spacing: -0.76px;
}

.selectPlanParaContainer {
  color: #a3aed0;
  margin: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: -0.36px;
}

/* click on active section  */

.container {
  margin-top: 30px;
  width: 35%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 55px;
  background: #fff;
  height: 60px;
  padding: 0 20px;
}

.containerChild1 {
  width: 60%;
}

.select_plan_active {
  cursor: pointer;
  color: #fff;

  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 73px;
  background: #328bde;
  /* Add any other styles you want for the active state */
}

.conChild2H2 {
  /* color: #8696AE; */
  padding: 10px 0;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.containerChild2 {
  width: 60%;
}

.paymentPlanContainer {
  display: flex;
  justify-content: center;
  align-items: stretch;
  /* ensure the children stretch to the container height */
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
  /* prevent horizontal overflow */
  flex-wrap: wrap;
  /* allow wrapping if needed */
}

.responsiveCardsContainer1 {
  display: flex;
  width: 50%;
}

.responsiveCardsContainer2 {
  display: flex;
  width: 50%;
}

.paymentPlanContainerChild {
  flex: 1;
  /* grow and shrink equally */
  min-width: 20%;
  /* ensure minimum width */
  box-sizing: border-box;
  /* include padding and border in width and height calculations */
  padding: 10px;
  /* add padding to ensure spacing */
}


@media (max-width: 1650px) {}

@media (max-width: 1440px) {}

@media (max-width: 1384px) {}

@media (max-width: 1200px) {
  .paymentPlanContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .responsiveCardsContainer1 {
    width: 60%;
  }

  .responsiveCardsContainer2 {
    width: 60%;
  }
}

@media (max-width: 1024px) {}

@media (max-width: 992px) {
  .paymentPlanContainer {
    flex-direction: column;
  }

  .container {
    width: 50%;
  }

  .responsiveCardsContainer1 {
    width: 80%;
  }

  .responsiveCardsContainer2 {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .container {
    width: 70%;
  }

  .responsiveCardsContainer1 {
    flex-direction: column;
    width: 60%;
  }

  .responsiveCardsContainer2 {
    flex-direction: column;
    width: 60%;
  }

}

@media (max-width: 576px) {
  .container {
    width: 90%;
  }

  .responsiveCardsContainer1 {
    width: 70%;
  }

  .responsiveCardsContainer2 {
    width: 70%;
  }

}

@media (max-width: 480px) {
  .selectPlanContainer {
    width: 80%;
  }

  .container {
    width: 100%;
  }


  .responsiveCardsContainer1 {
    width: 80%;
  }

  .responsiveCardsContainer2 {
    width: 80%;
  }

  .ulContainer {
    margin-left: 10px
  }
}

@media (max-width: 320px) {
  .selectPlanContainer {
    width: 70%;
  }

  .container {
    width: 100%;
  }

  .responsiveCardsContainer1 {
    width: 100%;
  }

  .responsiveCardsContainer2 {
    width: 100%;
  }
}

@media (max-width: 280px) {}