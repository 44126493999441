.login__container__form__verify {
  width: 100% !important;
  background-color: transparent;
}

.login__container__form__verify div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  background-color: transparent;
}

.login__container__form__verify div input {
  border-radius: 5px !important;
  font-size: 18px !important;
  background-color: transparent;
  border: 1px solid lightgray !important;
  margin-right: 1em;
  width: 50px !important;
  height: 50px !important;
}

.otp_controls_text {
  display: flex;
  justify-content: space-between;
}

.otp_controls_text_back {
  color: gray;
  cursor: pointer;
}

.otp_controls_text_back:hover {
  color: #5f5f5f;
}

.otp_controls_text_send_again {
  color: #1559be;
  cursor: pointer;
}