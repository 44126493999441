.exploreCategoryMainContainer {
  /* width: 25%; */
  min-height: 230px;
  height: auto;
  border-radius: 18.055px;
  border: 1.204px solid #eaeaea;
  padding: 5px;
  background: #fff;
  box-shadow: 3px 1px 44px -12px rgba(0, 0, 0, 0.16);
  /* position: relative; */
}

/* @media (max-width: 1000px) {
  .exploreCategoryMainContainer {
    width: 35%;
  }
}
@media (max-width: 700px) {
  .exploreCategoryMainContainer {
    width: 60%;
  }
}
@media (max-width: 400px) {
  .exploreCategoryMainContainer {
    height: 170px;
  }
} */
.exploreCategoryImgContainer {
  border-radius: 15.648px;
  height: 100px;
  padding: 30px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exploreCategoryContainer {
  width: 85%;
  margin: 0 auto;
  text-overflow: ellipsis;
}

.exploreCategoryContainer h3 {
  color: #000;

  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.exploreCategoryContainer p {
  font-family: 'Times New Roman', Times, serif;
  padding: 2px 0px;
  color: gray;
  height: 67px;
  text-align: center;
  font-family: Outfit;
  font-size: 10px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: 11px;
  overflow: hidden;
}

.exploreCategoryButton {
  border-radius: 33px;
  background: #328bde;
  color: #fff;

  text-align: center;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 14px */
  letter-spacing: 0.1px;
  margin: 0 auto;
  width: 100%;
  height: 30px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: 0;
}

.exploreCategoryButton:hover {
  background-color: #0393d4;
}

@media (max-width: 450px) {
  .exploreCategoryContainer p {
    font-size: 10px;
    line-height: 9px;
  }

  .exploreCategoryButton {
    height: 20px;
    font-size: 10px;
  }

  /* .exploreCategoryImgContainer {
    margin-bottom: 3px;
    height: 50px;
  } */
}