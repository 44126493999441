.SecondWelcomeWithCategoryMainContainer {
  width: 100%;
  /* background: #f5f9ffa0; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
}

@media (min-width: 1250px) {
  .DashBoardMainDiv {
    height: 100vh;
    /* background: #f5f9ff; */
  }
}

@media (min-width: 700px) {
  .DashBoardMainDiv {
    height: 100vh;
    /* background: #f5f9ff; */
  }
}

.SecondWelcomeWithCategoryMainContainerSecond {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SecondWelcomeWithCategoryContainer {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.SecondWelcomeWithCategoryHeadingContainer {
  color: #2b3674;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  line-height: 32px;
  /* 84.211% */
  letter-spacing: -0.76px;
}

.SecondWelcomeWithCategoryParaContainer {
  color: #a3aed0;
  margin-top: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: -0.36px;
}

.exploreCategoryMainContainerMap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  gap: 10px;
}

.SecondWelcome_Sidebar_text {
  font-size: 10px;
  font-weight: 600;
}

.SecondWelcome_Sidebar_color {
  display: flex;
  height: 100vh;
  flex-direction: column;
  color: rgb(35, 83, 156);
}

.active_NavbarIcon {
  font-weight: 800;
}

/* //////////////////////////////////Employee/////////////////////////////// */
.employee_heading {
  font-size: 22px;
  font-weight: 700;
}
.employee_Input_Block {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  padding: 10px 10px;
  border: 1px solid #919eab;
  border-radius: 7px;
}
.employee_second_container {
  overflow-x: scroll;
  height: 75vh;
  /* width: 96%; */
  padding: 5px 10px;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.employee_icon {
  font-size: 22px;
  color: #637381;
}
.employee_table {
  height: 90%;
  padding: 10px 0px;
}
.table_head {
  font-size: 14px;
  font-weight: 700;
  padding: 13px 5px;
  background-color: #f4f6f8;
  color: #637381;
  text-align: left;
}
.table_body {
  font-size: 14px;
  font-weight: 500;
  padding: 5px 5px;
  color: #596068;
}
.table-left {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.table-right {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.employee_check_box:hover {
  scale: 1.2;
}

input[type="checkbox"] {
  zoom: 1;
}

.employee_check_box::before {
  transform: scale(0.8);
  /* Adjust the scale to change the size of the checkmark */
}

@media (max-width: 630px) {
  .employee_second_container {
    overflow-x: scroll;
    height: 75vh;
    /* width: 390px; */
  }
  .table_body {
    font-size: 10px;
    padding: 5px 5px;
    color: #596068;
  }
}

@media (max-width: 550px) {
  .employee_heading {
    font-size: 14px;
    font-weight: 700;
  }
  .add_button {
    text-align: center;
    height: 25px;
    width: 70px;
    font-size: 9px;
  }
  .employee_Input_Block {
    font-size: 12px;
    width: 100%;
    gap: 10px;
    padding: 2px 10px;
  }

  .employee_icon {
    font-size: 17px;
  }

  .table_head {
    min-width: 40px;
    font-size: 11px;
    font-weight: 700;
    /* padding: 13px 5px; */
    background-color: #f4f6f8;
    color: #637381;
  }

  .table_body {
    font-size: 8px;
    color: #596068;
  }

  .page_control {
    font-size: 7px;
  }

  .employee_check_box {
    width: 10px;
  }
}

/* //////////////////////////////////End Employee///////////////////////////  */
/* //////////////////////////////////Add Employee///////////////////////////  */
.add_emp_upload_text {
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  color: #596068;
}
.add_emp_email_heading {
  font-size: 15px;
  font-weight: 600;
}
.add_emp_email_text {
  font-size: 13px;
  color: #596068;
}

/* The switch - the box around the slider */
.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 0.9em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background: #b8d9fa;
  border-radius: 50px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.slider:before {
  position: absolute;
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.9em;
  width: 0.9em;
  inset: 0;
  background-color: white;
  border-radius: 50px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.switch input:checked + .slider {
  background: #0974f1;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #0974f1;
}

.switch input:checked + .slider:before {
  transform: translateX(1.2em);
}

.emp_add_input {
  border: 1px solid #919eab;
  padding: 12px 10px;
  border-radius: 5px;
}

.emp_add_input:focus {
  outline: none;
}

.emp_add_input::placeholder {
  font-size: 13px;
}

.emp_add_button {
  font-size: 12px;
  padding: 7px 20px;
  background-color: #328bde;
  border-radius: 20px;
  color: white;
}

@media (max-width: 550px) {
  .add_emp_upload_text {
    font-size: 6px;
  }

  .add_emp_email_heading {
    font-size: 10px;
  }

  .add_emp_email_text {
    font-size: 6px;
  }
}

@media (max-width: 350px) {
  .employee_heading {
    font-size: 10px;
    font-weight: 700;
  }
  .add_button {
    height: 20px;
    width: 50px;
    font-size: 6px;
  }

  .text_navigate {
    font-size: 7px;
  }

  .emp_add_input {
    width: 120px;
    padding: 4px 10px;
  }
  .emp_add_button {
    height: 25px;
    width: 80px;
    font-size: 6px;
  }
}

/* //////////////////////////////////End Add Employee///////////////////////////  */
@media (max-width: 450px) {
  .SecondWelcome_Sidebar_text {
    font-size: 5px;
    font-weight: 500;
  }
}

@media (max-width: 420px) {
  .exitButton {
    margin-top: 300px;
  }
}

@media (min-width: 415px) {
  .exitButton {
    padding: 0px 20px;
  }
}

.exitButton {
  position: fixed;
  bottom: 0;
  margin-bottom: 10px;
  /* width: 100%; */
}
