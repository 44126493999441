.pricing-card {
    background: var(--Secondary-Primary-White, #FFF);
    color: #007bff;
    border-radius: 40px;
    /* padding: 50px; */
    width: 100%;
    height: 550px;
    text-align: center;
    margin: 20px auto;
    box-shadow: 3px 1px 44px -12px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    position: relative;
    padding: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}


.pricing-card:hover {
    background-color: #007bff;
    color: white;
    border-radius: 40px;
    /* padding: 50px; */
    width: 100%;
    text-align: center;
    margin: 20px auto;
    box-shadow: 3px 1px 44px -12px rgba(0, 0, 0, 0.16);
}

.tagline {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #328BDE;
    font-size: 12px;
    box-shadow: 0px 34px 60px 0px rgba(48, 47, 84, 0.10);
    color: white;
    border-radius: 10px;
    padding: 5px 10px;
    display: inline-block;
    margin-bottom: 10px;
    position: absolute;
    top: -15px;
    /* left: 25%; */
    font-weight: 600;
}


.pricing-card:hover .plan,
.pricing-card:hover .amount,
.pricing-card:hover .duration,
.pricing-card:hover .checkIcon,
.pricing-card:hover .lists,
.pricing-card:hover .amountText {
    color: white;
}

.pricing-card:hover .get-started {
    border: none;
}


.pricing-card:hover .tagline {
    color: black;
    background: #FFF;
}

.plan {
    color: #23539C;
    font-weight: 600;
    font-size: 18px;
    margin: 10px 0;
}

.price {
    font-size: 48px;
    margin: 10px 0;
}

.price .amount {
    font-weight: 600;
    color: #2B3674;
    /* display: block; */
}

.price .amountText {
    font-size: 30px;
    font-weight: 600;
    color: #2B3674;
    /* display: block; */
}

.price .duration {
    font-size: 14px;
    color: #A3AED0;
    margin-top: 0;
}

.features {
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.features li {
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 10px;
}



.get-started {
    border-radius: 60px;
    border: 1.5px solid #23539C;

    background: #FFF;
    color: #23539C;
    padding: 9px 25px;
    font-size: 16px;
    cursor: pointer;
}

.get-started:hover {
    background-color: #0056b3;
    color: white;
}

.ulContainer {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    gap: 20px;


}

.checkIcon {
    color: #23539C;
}

.lists {
    font-size: 13px;
    color: #2B3674;
}

.getStartedButtonContainerDiv {
    position: absolute;
    bottom: 30px;
}


@media (max-width: 1650px) {}

@media (max-width: 1440px) {}

@media (max-width: 1384px) {}

@media (max-width: 1200px) {}

@media (max-width: 1024px) {
    .tagline {
        /* left: 25%; */
    }
}

@media (max-width: 992px) {
    .tagline {
        /* left: 30%; */
    }
}

@media (max-width: 768px) {
    .tagline {
        /* left: 25%; */
    }
}

@media (max-width: 576px) {}

@media (max-width: 480px) {}

@media (max-width: 320px) {}

@media (max-width: 280px) {}