/* src/Navbar.css */
.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 10px 50px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.navbar-logo img {
    /* cursor: pointer; */
    height: 40px;
}

.navbar-links {
    list-style: none;
    display: flex;
    gap: 60px;
    margin: 0;
    padding: 0;
}

.navbar-links li {
    cursor: pointer;
    font-size: 16px;
    color: #333;
}

.navbar-links li.active,
.navbar-links li:hover {
    color: #007BFF;
    border-bottom: 2px solid #007BFF;
}

.login-button {
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 90px;
    height: 40px;
}

.login-button:hover {
    background-color: #0056b3;
}



@media (max-width: 321px) {
    .login-button {

        width: 70px;
        height: 40px;
    }

    .navbar-links li {
        width: 20%;
    }

    .navbar {
        padding: 10px 20px;
    }


}

@media (max-width: 576px) {
    .navbar-links li {
        width: 20%;
    }

}

@media (max-width: 768px) {

    .responsiveSide {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .hide {
        display: none;
    }

    .navbar {
        flex-direction: column;
        align-items: flex-start;
    }

    .navbar-logo {
        align-self: flex-start;
        margin-bottom: 10px;
    }

    .navbar-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .navbar-links {
        flex-direction: column;
        align-items: center;
        width: 100%;
        display: none;
        gap: 10px;
        animation: slideOut 0.3s forwards;
    }

    .navbar-links.show {
        display: flex;
        animation: slideIn 0.3s forwards;
    }

    .navbar-links li {
        padding: 10px 0;
        width: 7%;
        text-align: center;
    }

    .menu-toggle {
        display: block;
        cursor: pointer;
        font-size: 24px;
        margin-left: 20px;
        /* padding: 10px; */
    }

    .navbar-right .login-button {
        margin-left: auto;
    }

    @keyframes slideIn {
        from {
            transform: translateY(-100%);
        }

        to {
            transform: translateY(0);
        }
    }

    @keyframes slideOut {
        from {
            transform: translateY(0);
        }

        to {
            transform: translateY(-100%);
        }
    }
}

@media (min-width: 769px) {
    .menu-toggle {
        display: none;
    }

    .navbar-right {
        display: none;
    }

    .navbar-links li {
        width: 20%;
    }
}