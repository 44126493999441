.yourRoleMainContainer {
  width: 100vw;
  min-height: 100vh;
  background: #f5f9ff;
  display: flex;
  flex-direction: column;
}

.yourRoleMainContainerYourRole {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.yourRoleSpaceBtwContainer {
  flex: 1;
}

.yourRoleMainContainerSecond {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.yourRoleContainer {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.yourRoleHeadingContainer {
  color: #393939;
  margin-top: 30px;
  text-align: center;
  font-size: 38px;
  font-weight: 500;
  line-height: 32px;
  /* 84.211% */
  letter-spacing: -0.76px;
}

.yourRoleParaContainer {
  color: #959595;
  margin: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: -0.36px;
}

.yourRoleBottomPara {
  color: #a3aed0;

  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 200% */
  letter-spacing: -0.24px;
  /* position: absolute;

  bottom: 20px; */
  /* margin-top: 13%; */
  margin-bottom: 30px;
}

/* radio button css  */

.containerYourRole {
  width: 77%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}

.options-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.option {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 230px;
  width: 30%;
  height: 50px;
  /* border: 2px solid #e6e6e6; */
  border-radius: 30px;
  background: rgba(35, 83, 156, 0.03);
  color: #434343;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  gap: 10px;
  padding-left: 20px;
}

.optionLabel {
  font-size: 12px;
}

.option.selected {
  border: 1px solid #328bde;
  font-weight: 600;

  background: #fff;
  color: #1890ff;
}

.option input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.option label {
  cursor: pointer;
  margin: 0;
}

.option:hover {
  border-color: #1890ff;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #1890ff;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
  margin: 5px;
}

.buttonBack {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: white;
  color:
    #525A8F;
  cursor: pointer;
  transition: all 0.3s;
  margin: 5px;
}

.button:hover {
  background-color: #40a9ff;
}

.button:disabled {
  background-color: #e6e6e6;
  cursor: not-allowed;
}


.bottomParaMainContainer {
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  flex: 1;

}



@media (max-width: 1650px) {}

@media (max-width: 1440px) {}

@media (max-width: 1384px) {}

@media (max-width: 1200px) {}

@media (max-width: 1024px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {
  .yourRoleContainer {
    width: 80%;
  }
}

@media (max-width: 576px) {}

@media (max-width: 480px) {
  .buttonBack {
    height: 43px;
  }
}

@media (max-width: 320px) {
  .buttonBack {
    width: 150px;
  }

  .button {
    font-size: 12px;
    width: 100px;
    height: 43px;

  }
}

@media (max-width: 280px) {
  .buttonBack {
    width: 150px;
  }

  .button {
    font-size: 12px;
    width: 100px;
    height: 43px;

  }
}