/* Navbar Style  */

/* Pacific Rim Style  */

.NavBar_Main_Container {
  display: flex;
  justify-content: space-between;
  padding-right: 2%;
  align-items: center;
  position: fixed;
  z-index: 995;
  width: 100%;
  height: 90px;
  background-color: white;
  z-index: 999999999;
}

.navbarInnerContainer {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NavLink_App_Smaller_Devices {
  margin-right: 0.2%;
}

.NavbarButtonContainer {
  width: 80px;
  height: 35px;
  border-radius: 7px;
  font-weight: 600;
  flex-shrink: 0;
  color: white;
  background-color: #3b99f0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Toggle_Btn_CLose {
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.NavbarButtonContainer:hover {
  background-color: #1470c5;
}

.navbarConTactButtonIcon {
  margin-right: 5px;
  margin-top: 2px;
}

.headphoneLogo {
  height: 20px;
}

.Active_Header {
  transition: 400ms ease-in-out;
  /* background-color: rgba(255, 255, 255); */

  background-color: white;
}

.Logo_Text_style {
  font-size: 1.5rem;
  color: black;
  /* font-family: var(--SubHeading-font-family); */
}

.Logo_Saloon_Text_Style {
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  /* font-family: var(--SubHeading-font-family); */
}

.Join_Button_Style {
  padding: 12px;
  padding-left: 48px;
  padding-right: 48px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #353535;
  color: white;
  margin-right: 10px;
  font-family: var(--SubHeading-font-family);
  cursor: pointer;
}

.Join_Button_Style_Small_Device {
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  outline: none;
  border: none;
  border-radius: 5px;
  /* background-color: #353535; */
  color: #edd569;
  margin-right: 0px;
  margin-top: 5px;
  font-family: var(--SubHeading-font-family);
  cursor: pointer;
}

.Navlink_Main_Conatiner {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.NavbarButtonContainer.small_screen {
  display: none;
}

.Navlink_Wrapper {
  display: flex;
  width: 35%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: space-between;
}

.Navlink_Wrapper a {
  width: auto;
  text-decoration: none;
}

.Navlink_Wrapper_Second {
  display: flex;
  /* width: 100%; */
  align-items: center;
  justify-content: space-between;
}

.Navlink_Wrapper_Second a {
  width: auto;
}

.Button_Main_Container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.Button_Drop_Container {
  display: flex;
  align-items: center;
  background-color: #6fa66b;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
}

.Button_Drop_Container_Small_Device {
  display: flex;
  align-items: center;
  background-color: #6fa66b;
  border-radius: 5px;
  padding-left: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
}

.Select_Option_Style {
  font-size: 1.5rem !important;
  color: black;
  font-family: var(--SubHeading-font-family);
}

.Select_Option_Button {
  padding: 1px;
  font-size: 1.5rem;
  /* width: 10rem; */
  font-family: var(--SubHeading-font-family);
}

.Select_Option_Button_Small_Device {
  font-size: 1.5rem;
  /* padding-right: 5px; */
  font-family: var(--SubHeading-font-family);
}

.Select_Option_Style_Small_Device {
  font-size: 1.5rem;
  margin-top: 5px;
}

.Link_Style {
  font-size: 16px;
  text-decoration: none;
  color: black;
  font-family: var(--Heading-font-family);
  font-weight: 300;
  margin-right: 1%;
  cursor: pointer;
}

.Link_Style_Scroll {
  font-size: 16px;
  color: #edd569;
  font-family: var(--Heading-font-family);
  font-weight: 300;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
  margin-right: 1%;
}

.Link_Style:hover {
  /* background-color: rgba(0, 0, 0, 0.5); */
  font-family: var(--Heading-font-family);
  font-weight: 600;
  color: #edd569;
}

.Link_Style_Scroll:hover {
  /* background-color: rgba(0, 0, 0, 0.5); */
  font-family: var(--Heading-font-family);
  font-weight: 600;
}

.newMenu:hover {
  /* background-color: rgba(0, 0, 0, 0.5); */
  font-family: var(--Heading-font-family);
  width: 60%;
  font-weight: 600;
  color: white;
}

.Link_is_Active {
  color: black;
}

.Link_Style_Small_Device {
  font-size: 1.5rem;
  text-decoration: none;
  color: black;
  padding-top: 15px;
  font-family: var(--SubHeading-font-family);
  font-weight: 600;
  padding: 10px;
  border-radius: 25px;
  min-width: 65%;
  text-align: center;
}

.Navbar_Image_Style {
  height: 22px;
  width: 22px;
  border-radius: 100px;
}

.Select_Container {
  display: flex;
  align-items: center;
}

.NavLink_App_Smaller_Devices {
  display: none;
}

.NavLink_App_Smaller_Overlay {
  position: absolute;
  top: 92px;
  left: 0;
  right: 0;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  color: black;
  text-decoration: none;
  transition: 0s ease;
  flex-direction: column;
  overflow: hidden;
  z-index: 5;
  border-radius: 5px;
}

.Navlink_App_Links_Wrapper_SmallScreen_links {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 15px;
  padding-top: 5%;
  margin-bottom: 5%;
}

.Nav_Image_Style {
  height: auto;
  width: 80%;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.newMenu {
  font-size: 1.5rem;
  color: black;
  font-weight: 500;
  margin-left: 4rem;
  font-family: var(--Heading-font-family);
  cursor: pointer;
}



@media (max-width: 321px) {
  .navbarLogoContainer img {
    height: 80px;
    width: 80px;
    overflow: hidden;
  }

  .NavLink_App_Smaller_Devices {
    display: flex;
    margin-left: -70px;
    margin-bottom: 3%;
  }

  .navbarConTactButton {
    display: none;
  }

  .NavBar_Main_Container {}

  .NavbarButtonContainer {
    display: none;
  }

  .NavbarButtonContainer.small_screen {
    margin-right: 85px;
    display: flex;
    align-items: center;
  }

  .collapse_button_number {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
  }

  .Navlink_Wrapper {
    display: none;
  }

  .Nav_Image_Style {
    height: auto;
    width: 100px;
  }

  .headphoneLogo {
    width: 10px;
  }

  .Link_Style {
    margin-bottom: 2%;
    font-weight: 400;
    font-size: 14px;
  }

  .Link_Style_Scroll {
    margin-bottom: 2%;
    font-weight: 400;
    font-size: 14px;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
  }

  .NavLink_App_Smaller_Overlay {
    top: 92px;
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  .navbarLogoContainer img {
    height: 100px;
    width: 100px;
    overflow: hidden;
  }

  .NavLink_App_Smaller_Devices {
    display: flex;
    margin-bottom: 4%;
    margin-right: 27%;
    /* margin-left: 20; */
  }

  .navbarConTactButton {
    display: none;
  }

  .NavBar_Main_Container {
    /* display: flex;
    justify-content: space-between;
    padding-left: 0%;
    padding-right: 0%;
    align-items: center;
    padding-top: 2.4rem;
    padding-bottom: 1.4rem;
    position: fixed;
    z-index: 995;
    width: 100%;
    height: 70px; */
  }

  .Navlink_Wrapper {
    display: none;
  }

  .NavbarButtonContainer {
    display: none;
  }

  .NavbarButtonContainer.small_screen {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .collapse_button_number {
    display: flex;
    align-items: center;
  }

  .Link_Style {
    margin-bottom: 2%;
    font-weight: 400;
    font-size: 14px;
  }

  .Link_Style_Scroll {
    margin-bottom: 2%;
    font-weight: 400;
    font-size: 14px;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
  }

  .Nav_Image_Style {
    height: auto;
    width: 100px;
  }

  .NavbarButtonContainer {
    display: none;
  }

  .NavbarButtonContainer.small_screen {
    display: flex;
    align-items: center;
  }

  .collapse_button_number {
    display: flex;
    align-items: center;
    margin-right: 13px;
  }

  .headphoneLogo {
    width: 10px;
  }

  .NavLink_App_Smaller_Overlay {
    top: 92px;
  }
}

@media (min-width: 481px) and (max-width: 576px) {
  .navbarLogoContainer img {
    height: 100px;
    width: 100px;
    overflow: hidden;
  }

  .NavLink_App_Smaller_Devices {
    display: flex;
    margin-bottom: 4%;
    margin-right: 5%;
  }

  .NavBar_Main_Container {
    /* display: flex;
    justify-content: space-between;
    padding-left: 0%;
    padding-right: 0%;
    align-items: center;
    padding-top: 2.4rem;
    padding-bottom: 1.4rem;
    position: fixed;
    z-index: 995;
    width: 100%;
    height: 70px; */
  }

  .Navlink_Wrapper {
    display: none;
  }

  .NavbarButtonContainer {
    display: none;
  }

  .NavbarButtonContainer.small_screen {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .collapse_button_number {
    display: flex;
    align-items: center;
  }

  .Link_Style {
    margin-bottom: 2%;
    font-weight: 400;
    font-size: 14px;
  }

  .Link_Style_Scroll {
    margin-bottom: 2%;
    font-weight: 400;
    font-size: 14px;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
  }

  .Nav_Image_Style {
    height: auto;
    width: 100px;
  }

  .navbarConTactButton {
    width: 211px;
    height: 35px;
    font-size: 13px;
    margin-right: 25px;
  }

  .headphoneLogo {
    width: 10px;
  }

  .NavLink_App_Smaller_Overlay {
    top: 92px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .navbarLogoContainer img {
    height: 100px;
    width: 100px;
    overflow: hidden;
  }

  .NavLink_App_Smaller_Devices {
    display: flex;
    margin-bottom: 4%;
    margin-right: 0%;
  }

  .NavBar_Main_Container {
    /* display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2.4rem;
    padding-bottom: 1.4rem;
    position: fixed;
    z-index: 995;
    width: 100%;
    height: 70px; */
  }

  .Navlink_Wrapper {
    display: none;
  }

  .NavbarButtonContainer {
    display: none;
  }

  .NavbarButtonContainer.small_screen {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .collapse_button_number {
    display: flex;
    align-items: center;
  }

  .Link_Style {
    margin-bottom: 2%;
    font-weight: 400;
    font-size: 14px;
  }

  .Link_Style_Scroll {
    margin-bottom: 2%;
    font-weight: 400;
    font-size: 14px;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
  }

  .Nav_Image_Style {
    height: auto;
    width: 100px;
  }

  .navbarConTactButton {
    width: 210px;
    height: 35px;
    font-size: 13px;
    margin-right: 5px;
  }

  .headphoneLogo {
    width: 10px;
  }

  .NavLink_App_Smaller_Overlay {
    top: 92px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .NavLink_App_Smaller_Devices {
    display: flex;
  }

  /* .NavBar_Main_Container {
    display: flex;
    justify-content: space-between;
    padding-right: 3%;
    align-items: center;
    padding-top: 2.4rem;
    padding-bottom: 1.4rem;
    position: fixed;
    z-index: 995;
    width: 100%;
    height: 100px;

  } */

  .NavbarButtonContainer {
    display: none;
  }

  .NavbarButtonContainer.small_screen {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .collapse_button_number {
    display: flex;
    align-items: center;
  }

  .Navlink_Wrapper {
    display: none;
  }

  .Nav_Image_Style {
    /* height: auto;
    width: 200px; */
  }

  .navbarConTactButton {
    /* width: 244px;
    height: 45px;
    font-size: 15px;
    margin-top: 7%; */
  }

  .headphoneLogo {
    /* height: 15px; */
  }

  .Link_Style {
    /* margin-bottom: 1%; */
  }

  .Link_Style_Scroll {
    /* margin-bottom: 1%; */
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .NavLink_App_Smaller_Devices {
    display: flex;
  }

  .NavbarButtonContainer {
    display: none;
  }

  .NavbarButtonContainer.small_screen {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }

  .collapse_button_number {
    display: flex;
    align-items: center;
  }

  .NavBar_Main_Container {
    /* display: flex;
    justify-content: space-between;
    padding-right: 3%;
    align-items: center;
    padding-top: 2.4rem;
    padding-bottom: 1.4rem;
    position: fixed;
    z-index: 995;
    width: 100%;
    height: 100px; */
  }

  .Navlink_Wrapper {
    display: none;
  }

  .Nav_Image_Style {
    height: auto;
    width: 200px;
  }

  .navbarConTactButton {
    width: 244px;
    height: 45px;
    font-size: 15px;
    margin-left: -43%;
    margin-top: 5%;
  }

  .headphoneLogo {
    height: 15px;
  }

  .NavbarButtonContainer {
    /* width: 100px;
    margin-left: 200px; */
  }

  .Link_Style {
    margin-bottom: 1%;
  }

  .Link_Style_Scroll {
    margin-bottom: 1%;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .navbarConTactButton {
    width: 244px;
    height: 45px;
    font-size: 15px;
    margin-left: -43%;
  }
}

@media (min-width: 1281px) {
  .NavBar_Main_Container {
    /* height: 90px; */
  }

  .Navlink_Wrapper {}

  .Nav_Image_Style {
    margin-right: 10%;
    height: auto;
    width: 125px;
  }

  .navbarConTactButton {
    width: 244px;
    height: 45px;
    font-size: 15px;
  }

  .Link_Style {
    font-weight: 300;
    font-size: 13px;
  }

  .Link_Style_Scroll {
    font-weight: 300;
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .NavBar_Main_Container {
    /* height: 90px; */
  }

  .Navlink_Wrapper {}

  .Nav_Image_Style {
    margin-right: 10%;
    height: auto;
    width: 125px;
  }

  .navbarConTactButton {
    width: 244px;
    height: 45px;
    font-size: 15px;
    margin-left: 17%;
    margin-top: 15px;
  }

  .Link_Style {
    font-weight: 400;
    font-size: 16px;
  }

  .Link_Style_Scroll {
    font-weight: 400;
    font-size: 16px;
  }

  .NavBar_Main_Container {
    /* justify-content: space-around; */
  }
}

@media (min-width: 1384px) and (max-width: 1440px) {}

@media (min-width: 1440px) and (max-width: 1650px) {}

@media (min-width: 1650px) {}