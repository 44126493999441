.plane_block {
  padding-top: 6%;
  padding-bottom: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.plans_header {
  position: relative;
}

.plans_header_text {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}

.plans-_block_under_line {
  top: 65%;
  position: absolute;
}

.plans_block_under_line_img {
  width: 65%;
  margin-top: -1em;
  height: auto;
  margin-left: 20%;
}

.plane_block_bottom_part {
  padding-top: 3%;
  display: flex;
  width: 98vw;
  justify-content: space-evenly;
}

.plane_block_bottom_detail {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 25%;
  border: 0.5px solid rgb(237, 237, 237);
  /* align-items: center; */
  border-radius: 15px;
  cursor: pointer;
}

.plane_block_bottom_detail:hover {
  background: linear-gradient(to bottom, rgba(8, 48, 84, 1), rgba(57, 149, 235, 1));
  color: white;
}

.size_of_block {
  padding: 3% 0;
  font-weight: 500;
  width: 100%;
  font-size: 25px;
  display: flex;
  justify-content: space-between;
}

.plane_block_content_important {
  padding: 0.1em 0.6em;
  font-weight: 400;
  font-size: 13px;
  background-color: rgb(66, 166, 216);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plane_block_amount {
  display: flex;
  width: 15vw;
  justify-content: flex-start;
  padding: 3%;
}

.plane_block_content_amount {
  font-size: 20px;
  font-weight: 500;
}

.plane_block_content_price {
  margin-left: 2px;
  font-size: 35px;
  font-weight: 700;
}

.plane_block_content_month {
  font-size: 20px;
  font-weight: 500;
}

.plane_block_member {
  color: rgb(144, 158, 177);
  font-size: 19px;
  padding: 4%;
  display: flex;
  justify-content: center;
}

.plane_block_bottom_detail:hover .plane_block_member {
  color: white;
}

.plane_block_get_started {
  margin: 4%;
  font-size: 19px;
  font-weight: 500;
  border: 1px solid;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 15%;
  padding-right: 15%;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  background-color: rgb(51, 141, 224);
  border: 2px solid rgb(51, 141, 224);
}

.plane_block_get_started2 {
  margin: 21% 2% 3% 3%;
  font-size: 19px;
  font-weight: 500;
  border: 1px solid;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 15%;
  padding-right: 15%;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  background-color: rgb(51, 141, 224);
  border: 2px solid rgb(51, 141, 224);
}
.plane_block_bottom_detail:hover .plane_block_get_started2 {
  border: 2px solid white;
}

.plane_block_bottom_detail:hover .plane_block_get_started {
  border: 2px solid white;
}

.plane_block_learn_more {
  margin: 3%;
  font-size: 19px;
  font-weight: 500;
  border: 1px solid;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 15%;
  padding-right: 15%;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  background-color: rgb(245, 246, 246);
  border: rgb(245, 246, 246);
}

.plane_block_bottom_detail:hover .plane_block_learn_more {
  color: black;
  background-color: white;
}

.plane_block_content_content_size_detail {
  /* width: 15vw; */
  display: flex;
  justify-content: space-between;
}

.per_month {
  color: rgb(106, 124, 150);
}

.plane_block_bottom_detail:hover .per_month {
  color: white;
}

.card__main__container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /* width: 100%;
  height: 100%; */
  z-index: 9999;
  /* background: transparent; */
  background-color: rgba(36, 36, 36, 0.475);
}

.card_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: whitesmoke;
  width: 40%;
  border-radius: 10px;
}
.sub_card_container {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.pay_btn {
  width: auto;
  border-radius: 5px;
  padding: 5px;
  background-color: rgb(51, 141, 224);
  color: white;
}

@media screen and (max-width: 2100px) {
  .plane_block_get_started2 {
    margin: 15% 2% 3% 3%;
  }
}

@media screen and (max-width: 1850px) {
  .plane_block_get_started2 {
    margin: 17% 2% 3% 3%;
  }
}

@media screen and (max-width: 1536px) {
  .plane_block_get_started2 {
    margin: 19% 2% 3% 3%;
  }
}

@media screen and (max-width: 1367px) {
  .plane_block_bottom_detail {
    width: 24%;
  }
  .plans_block_under_line_img {
    width: 55%;
    margin-top: -0.5%;
  }
  .plane_block_get_started2 {
    margin: 22% 2% 3% 3%;
  }
}

/* @media screen and (min-width: 1230px) {
  
} */

@media screen and (max-width: 1242px) {
  .plane_block_bottom_detail {
    width: 24%;
  }
  .plans_block_under_line_img {
    width: 55%;
    margin-top: -0.5%;
  }

  .plane_block_get_started2 {
    margin: 13.6% 2% 3% 3%;
  }
  .plane_block_amount {
    width: 17vw;
  }
}

@media screen and (max-width: 1025px) {
  .plane_block_content_important {
    font-size: 12px;
  }

  .size_of_block {
    font-size: 17px;
  }

  .plane_block_content_price {
    font-size: 27px;
  }

  .plane_block_content_month {
    font-size: 10px;
  }

  .plane_block_content_amount_crunncy {
    margin-top: -1%;
    font-size: 14px;
  }

  .plane_block_member {
    font-size: 1rem;
  }

  .plane_block_get_started {
    font-size: 15px;
  }
  .plans_block_under_line_img {
    width: 55%;
    margin-top: -0.5%;
  }

  .plane_block_get_started2 {
    margin: 15% 2% 3% 3%;
    font-size: 15px;
  }
}

@media screen and (max-width: 850px) {
  .plane_block_bottom_part {
    flex-direction: column;
    align-items: center;
  }

  .plane_block_bottom_detail {
    border: 4px solid rgb(237, 237, 237);
    margin-bottom: 3%;
    width: 90vw;
  }

  .plane_block_content_month {
    font-size: 14px;
  }

  .plane_block_content_content_size_detail {
    width: 37vw;
  }

  .size_of_block {
    /* width: 70vw; */
  }

  .plans_header_text {
    font-size: 30px;
    margin-top: 3%;
  }

  .plane_block_amount {
    width: 40vw;
  }
  .plans_block_under_line_img {
    width: 50%;
    margin-top: -0.5%;
    margin-right: -5%;
  }
  .plane_block_get_started2 {
    margin: 35% 2% 3% 3%;
  }
}

@media screen and (max-width: 600px) {
  .plans_block_under_line_img {
    width: 50%;
    margin-top: -0.5%;
    margin-left: 20%;
  }
}
