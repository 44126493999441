.navbarMainContainer {
    background: #FFF;
    width: 100vw;
    /* height: 50px; */
    /* box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.16); */
    overflow: hidden;
    z-index: 10000;

}

.navbarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 40px;
}

.imageMainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

}