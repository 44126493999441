/* #communication {
  height: 79vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */
@media (max-width: 450px) {
  #communication {
    height: 80vh;
  }

  .hight_sideBar {
    height: 100%;
  }
}

#chat__panel {
  flex: 1;
  border-bottom: 5px solid white;
}

.chatBot_main_background {
  background-color: rgb(245, 249, 255);
  height: 100vh;
}

.chatBot_main_heading {
  font-size: 20px;
  color: #2b3674;
}

.chatBot_main_heading_text {
  font-size: 10px;
}

.chatBot_main_group_heading {
  color: #2b3674;
  font-size: 10px;
}

.chatBot_main_group_heading_text {
  font-size: 8.5px;
}

@media (max-width: 400px) {
  .chatBot_main_group_heading_text {
    font-size: 6px;
  }
}

@media (max-width: 450px) {
  .chatBot_main_group_heading {
    font-size: 9px;
  }
}

.main_chatBot_input {
  font-size: 10px;
  padding: 5px 0px;
  outline: none;
  padding-left: 20px;
}

.main_chatBot_input:focus {
  border: 1px solid skyblue;
}

.main_chatBot_input::placeholder {
  font-size: 10px;
  padding: 3px;
}

.chatBot_communi_chat {
  font-size: 11px;
  max-width: 700px;
  width: auto;
}

.chatBot_communi_detail {
  font-size: 11px;
  width: 30px;
}

@media (max-width: 450px) {
  .chatBot_communi_chat {
    font-size: 8px;
  }
}

.chatBot_communi_chat_date {
  text-align: center;
  font-size: 8px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

@media (max-width: 450px) {
  #collapseButton {
    width: 10px;
    font-size: small;
  }
}

@media (max-width: 450px) {
  #newButton {
    width: 70px;
    font-size: 9px;
    padding: 4px 0px;
  }
}

@media (max-width: 450px) {
  .category-item {
    width: 110px;
    font-size: 8px;
    padding: 4px 0px;
  }
}

@media (max-width: 450px) {
  #collap {
    width: 155px;
    font-size: 10px;
    padding: 4px 0px;
  }
}

.collapsible {
  background-color: rgb(250, 250, 250);
  color: black;
  cursor: pointer;
  padding: 8px;
  width: 100%;
  text-align: left;
  font-weight: 500;
  outline: none;
  font-size: 13px;
  border-radius: 20px;
}

.content {
  padding-top: 10px;
  overflow: hidden;
}

@media (max-width: 640px) {
  #contentButton {
    width: 45%;
    position: absolute;
    z-index: 2;
  }
}

#contentButton {
  display: hidden;
}

.collapsed {
  display: none;
}