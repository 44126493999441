.Home-main-container {
  display: "flex";
  flex-direction: column;
}

.orbix-first-block {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-top: 7%;
}

.orbix-landing-page {
  background-image: url("../../assets/main-image.png");
  height: auto;
  /* width: 100vw; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}

.orbix-first-block {
  display: flex;
  justify-content: center;
  margin: 0 30px;
  padding-top: 7%;
}

.orbix-first-block-text {
  width: 40vw;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.orbix-landing-main-text {
  font-size: 35px;
  font-weight: 700;
  position: relative;
  z-index: 10;
}

.orbix-landing-main-text-1 {
  margin-top: 4%;
  font-size: 28px;
  color: #d4d4d4;
}

.orbix-landing-main-text-2 {
  font-size: 15px;
  color: #d4d4d4;
}

.button-block {
  margin-top: 3%;
}

.join-button {
  /* width: 8vw; */
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 4%;
  padding-right: 4%;
  margin-right: 2%;
  background-color: rgb(0, 26, 38);
  color: white;
  border: solid 1px white;
  cursor: pointer;
}

.join-button:hover {
  border: none;
  background: linear-gradient(to bottom,
      rgba(8, 48, 84, 1),
      rgba(57, 149, 235, 1));
}

.read-butoon {
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 4%;
  padding-right: 4%;
  margin-right: 2%;
  background-color: rgb(0, 26, 38);
  color: white;
  border: solid 1px white;
  cursor: pointer;
}

.read-butoon:hover {
  border: none;
  background: linear-gradient(to bottom,
      rgba(8, 48, 84, 1),
      rgba(57, 149, 235, 1));
}

.color-under-line {
  margin-top: -2%;
  height: 2vh;
  width: 66.5%;
  background-color: rgb(232, 182, 7);
}

.orbix-second-block {
  position: relative;
  float: right;
  margin-top: -3%;
  margin-right: 4%;
  width: 31vw;
  background: linear-gradient(to bottom,
      rgba(61, 155, 242, 1),
      rgba(19, 92, 159, 1));
}

.orbix-small-block {
  padding: 4%;
  display: flex;
  color: white;
}

.svg1 {
  margin: 7px;
  border-radius: 50px;
  background-color: white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg1 img {
  width: 30px;
  height: auto;
}

.orbix-second-block-text-1 {
  margin-left: 5px;
  margin-top: 7px;
  width: 20vw;
  font-weight: 700;
}

.orbix-second-block-text {
  padding-right: 12%;
  padding-left: 1%;
}

.orbix-second-block-text-2 {
  padding-right: 10%;
  margin-left: 5px;
  margin-top: 2%;
  width: 20vw;
}

.arrow-sign {
  background-color: rgb(21, 88, 151);
  position: relative;
  float: right;
  padding: 3%;
  cursor: pointer;
}

.arrow-sign:hover {
  background: linear-gradient(to bottom,
      rgba(8, 48, 84, 1),
      rgba(57, 149, 235, 1));
}

.orbix-landing-main-text-main-detail {
  padding-bottom: 3%;
}

.partal__main__container {
  display: flex;
  min-height: 100vh;
  background: url("../../assets//portal-bg.png");
  /* background-color: red; */
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  /* overflow-y: hidden; */
}

.orbix-first-block-text {
  width: 48vw;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.orbix-landing-main-text {
  font-size: 35px;
  font-weight: 700;
  position: relative;
  z-index: 10;
}

.orbix-landing-main-text-1 {
  margin-top: 4%;
  font-size: 28px;
  color: #d4d4d4;
}

.orbix-landing-main-text-2 {
  font-size: 15px;
  color: #d4d4d4;
}

.button-block {
  margin-top: 3%;
}

.join-button {
  /* width: 8vw; */
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 4%;
  padding-right: 4%;
  margin-right: 2%;
  background-color: rgb(0, 26, 38);
  color: white;
  border: solid 1px white;
  cursor: pointer;
}

.join-button:hover {
  border: none;
  background: linear-gradient(to bottom,
      rgba(8, 48, 84, 1),
      rgba(57, 149, 235, 1));
}

.read-butoon {
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 4%;
  padding-right: 4%;
  margin-right: 2%;
  background-color: rgb(0, 26, 38);
  color: white;
  border: solid 1px white;
  cursor: pointer;
}

.read-butoon:hover {
  border: none;
  background: linear-gradient(to bottom,
      rgba(8, 48, 84, 1),
      rgba(57, 149, 235, 1));
}

.color-under-line {
  margin-top: -2%;
  height: 2vh;
  width: 66.5%;
  background-color: rgb(232, 182, 7);
}

.orbix-second-block {
  width: 31vw;
  padding: 2%;
  margin-left: auto;
  background: linear-gradient(to bottom,
      rgba(61, 155, 242, 1),
      rgba(19, 92, 159, 1));
  margin-right: 3%;
  margin-top: -3%;
}

.orbix-small-block {
  position: relative;
  padding: 1%;
  display: flex;
  color: white;
  width: 90%;
}

.svg1 {
  /* margin: 7px; */
  border-radius: 25px;
  background-color: white;
  /* width: 50px; */
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg1 img {
  width: 30px;
  height: auto;
}

.orbix-second-block-text-1 {
  margin-left: 5px;
  margin-top: 7px;
  width: 20vw;
  font-weight: 700;
}

.orbix-second-block-text {
  padding-right: 12%;
  padding-left: 1%;
}

.orbix-second-block-text-2 {
  padding-right: 10%;
  margin-left: 5px;
  margin-top: 2%;
  width: 20vw;
}

.arrow-sign {
  padding: 3%;
  cursor: pointer;
  margin-left: -6%;
  margin-bottom: -6%;
  align-self: flex-end;
}

.arrow-sign:hover {
  transform: scale(1.1);
}

.orbix-landing-main-text-main-detail {
  padding-bottom: 3%;
}

.partal__main__container {
  display: flex;
  min-height: 100vh;
  background: url("../../assets/portal-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.sidebar__main__container {
  width: 20%;
  /* background: black; */
  color: azure;
  max-height: 100vh;
  padding: 1em;
  position: sticky;
  background: rgba(0, 0, 0, 0.15);

  backdrop-filter: blur(15px);
  transition: width 2s ease;
}

.sidebar__main__container__close {
  /* transition: width 2s ease; */
  width: 0;
  padding: 0;
  display: none;
}

.portal__jumbotron {
  width: 80vw;
  padding: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  transition: width 2s ease;
}

.portal__jumbotron__expand {
  width: 90vw;
  /* transition: width 1s ease; */
  margin: 0 auto;
  padding: 2em;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  height: 100vh;
}

.flip__icon {
  border: 1px solid white;
  padding: 0.5em 0.8em;
  border-radius: 5px;
  height: fit-content;
  color: azure;
  margin: 1em 0em 0em 1em;
  cursor: pointer;
  width: fit-content;
}

.subscription_main {
  padding: 1em;
  align-items: center;
  display: flex;
  /* width: 62vw; */
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.15);
  /* background: white; */
  backdrop-filter: blur(2.5px);
  flex-direction: column;
  /* height: 78.5vh;
    overflow: hidden; */
  color: white;
}

.subscription_main_header_text {
  font-size: 35px;
  font-weight: 700;
}

.subscription_main_header_part_1 {
  margin-top: 2%;
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.subscription_main_header_Payment_History {
  font-size: 23px;
  font-weight: 500;
}

.subscription_main_header_Payment_History2 {
  font-size: 14px;
  font-weight: 300;
}

.subscription_main_header_download_button {
  align-items: center;
  display: flex;
  padding: 1% 2%;

  border-radius: 30px;
  font-size: 20px;
  font-weight: 500;
}

.subscription_main_header_download_button img {
  width: 80%;
  margin-right: 3%;
}

.invoice-details {
  /* width: 63vw; */
  display: flex;
  /* justify-content: space-evenly; */
  margin-top: 3%;
  align-items: center;
  font-size: 20px;
}

.invoice-payment_detail {
  display: flex;
  min-width: 250px;
  align-items: center;
  text-decoration: none;
  color: white;
}

.invoice-payment_detail:hover {
  text-decoration: underline;
}

.invoice-payment {
  cursor: pointer;
  font-size: 18px;
}

.invoice-amount,
.invoice-date {
  /* width: 15%; */
  font-size: 18px;
  min-width: 150px;
}

.invoice-payment_detail img {
  width: 6%;
  margin-right: 4%;
}

.invoice-download img {
  width: 13%;
  margin-right: 6%;
}

.Payment_Invoice_header {
  display: flex;

  align-items: center;
  justify-content: center;
}

.subscription_detail {
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(40.5px);
  margin-top: 2%;
  padding-bottom: 1%;
  border-radius: 25px;
  width: 95%;
}

.invoice_scrollar {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 57vh;
  padding: 2%;
}

.invoice_scrollar::-webkit-scrollbar {
  display: none;
}

.invoice-details_1 {
  /* width: 70vw; */
  display: flex;
  align-items: center;
  /* background-color: var(--invoice-details_1-background-color); */
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  font-size: 21px;
  padding: 2% 2% 0 2%;
  /* padding-left: 6%; */
}

.invoice-status {
  width: 4vw;
}

.Payment_Invoice_header-text {
  font-size: 19px;
  font-weight: 600;
}

.Payment_Invoice_header_1 {
  display: flex;
  align-items: center;
  width: 15vw;
}

.Payment_Invoice_header_1 img {
  width: 0.93vw;
}

.invoice-download {
  width: 8vw;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.Payment_Invoice_header_2 {
  width: 15%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.Payment_Invoice_header_3 {
  margin-left: 5.2%;
  display: flex;
  width: 21%;
  align-items: center;
}

.Payment_Invoice_header_4 {
  display: flex;
  align-items: center;
}

.invoice-dot {
  margin-right: 6%;
  width: 10px;
  height: 10px;
  border-radius: 25px;
}

.states_dot {
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
}

.invoice-checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  margin-right: 10px;
}

input[type="checkbox"].invoice-checkbox {
  height: 14px;
  width: 13px;
  visibility: visible;
}

.PaymentDetail_page {
  width: 65vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(9.5px);
  padding: 2%;
  font-weight: 200;
}

.PaymentDetail_page_1 {
  margin: 1em;
  border-radius: 12px;
  background: rgba(217, 217, 217, 0.12);
  backdrop-filter: blur(40.5px);
  width: 95%;
  padding: 2em;
  height: 78vh;
  overflow: auto;
}

.PaymentDetail_page_2 {
  display: flex;
  width: 60vw;
  flex-direction: column;
}

.PaymentDetail_person_detail {
  display: flex;
  justify-content: space-between;
  padding: 1.5em;
  border-bottom: 1px solid rgba(56, 142, 230, 0.73);
}

.PaymentDetail_person_detail_1 {
  display: flex;
  margin: 2em 0em;
  border-bottom: 1px solid rgba(56, 142, 230, 0.73);
  padding-bottom: 0.8em;
  width: 90%;
}

.PaymentDetail_person_payment {
  display: flex;
  margin-top: 2em;
  padding-bottom: 1em;
  border-bottom: 1px solid rgba(56, 142, 230, 0.73);
  width: 90%;
}

.PaymentDetail_person_payment_1 {
  display: flex;
  margin-top: 2em;
  padding-bottom: 3em;
  width: 100%;
}

.payment_item {
  font-weight: 600;
  font-size: 20px;
}

.States_items {
  border-radius: 20px;
  background-color: rgba(56, 142, 230, 0.59);
  padding: 0.2em 1em;
  margin-top: 1em;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.States_items_main {
  text-align: center;
}

.States_items_main-1 {
  margin-top: 9%;
  color: white;
  font-weight: 400;
}

.PaymentDetail_person_detail_2 {
  width: 50%;
}

.payment_to_from {
  font-weight: 400;
  margin-bottom: 3%;
}

.payment_person_image {
  display: flex;
  align-items: center;
  margin-bottom: 2%;
}

.payment_person_image img {
  width: 74%;
  height: 5vh;
  border-radius: 25px;
}

.payment_person_image_1 {
  display: flex;
}

.payment_person_image_1 img {
  width: 74%;
  height: 5vh;
  border-radius: 5px;
}

.payment-person-name {
  font-weight: 500;
}

.payment-person-email {
  font-size: 15px;
  font-weight: 400;
}

.PaymentDetail_person_payment_detail {
  font-weight: 500;
  width: 30vw;
}

.PaymentDetail-Subtotal {
  padding-bottom: 4%;
}

.payment_image {
  width: 3vw;
}

.PaymentDetail-Subtotal_1 {
  padding-bottom: 28%;
}

.Payment_Summary {
  padding-top: 2%;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0;
}

.css-i4bv87-MuiSvgIcon-root {
  color: white;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
  background-color: transparent !important;
  color: white !important;
}

.css-67l5gl {
  background-color: transparent !important;
  color: white !important;
  box-shadow: none !important;
}

.css-1fx8m19 {
  color: white !important;
}

.Chat_message__6KkQc {
  text-align: justify !important;
}



@media screen and (max-width: 850px) {
  .orbix-first-block {
    align-items: center;
    flex-direction: column;
    width: 85vw;
    padding-bottom: 5%;
  }

  .orbix-landing-main-text {
    width: 75vw;
    font-size: 25px;
    display: flex;
    top: 40px;
    justify-content: flex-start;
    z-index: 0;
  }

  .orbix-landing-main-text-1 {
    margin-top: 9%;
    font-size: 20px;
  }

  .orbix-landing-main-text-2 {
    font-size: 13px;
  }

  .orbix-first-block-text {
    margin: 0px 30px;
    margin-top: 50px;
    justify-content: flex-start;
    width: auto;
  }

  .orbixrebort img {
    width: 70%;
    height: auto;
  }

  .orbixrebort {
    width: 100vw;
    display: flex;
    justify-content: center;
  }

  .orbix-landing-page {
    background-position: 50%;
  }

  .color-under-line {
    display: none;
  }

  .join-button {
    width: 100px;
    font-size: 10px;
  }

  .read-butoon {
    width: auto;
  }

  .orbix-second-block {
    /* top: 100vh; */
    width: 50vw;
  }

  .orbix-second-block-text-2 {
    /* width: 100%; */
  }

  .orbix-second-block-text-1 {
    /* width: 100%; */
  }

  .orbix-second-block-text {
    padding-right: 6%;
  }

  .read-butoon:hover {
    background-color: rgb(50, 139, 222);
    border-color: linear-gradient(to bottom,
        rgba(8, 48, 84, 1),
        rgba(57, 149, 235, 1));
  }

  .join-button:hover {
    background-color: rgb(50, 139, 222);
    border-color: linear-gradient(to bottom,
        rgba(8, 48, 84, 1),
        rgba(57, 149, 235, 1));
  }

  .subscription_main_header_part_1 {
    flex-direction: column;
  }

  .subscription_main_header_download_button {
    margin: 0.8em 0em;
    font-size: 16px;
  }

  .invoice-details_1 {
    padding: 1.5em;
    /* max-width: 100%;
      overflow: auto; */
  }

  .invoice_scrollar {
    padding: 1.5em;
    overflow: unset;
  }

  .invoice-download img {
    width: 50%;
  }

  .subscription_detail {
    max-width: 95%;
    overflow: auto;

    max-height: 65vh;
  }

  .invoice_scrollar {
    max-width: 95%;
    overflow: unset;
  }

  .PaymentDetail_person_detail {
    flex-direction: column;
    align-items: flex-start;
    padding: 1em 0em;
  }

  .States_items_main {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    width: 100%;
    justify-content: space-between;
  }

  .States_items,
  .States_items_main-1 {
    margin-top: 0;
  }

  .PaymentDetail_page_1 {
    padding: 1em;
  }

  .PaymentDetail_page_2 {
    width: 100%;
  }

  .PaymentDetail_person_detail_1 {
    width: 100%;
    flex-direction: column;
  }

  .PaymentDetail_person_detail_2 {
    width: 100%;
    margin-bottom: 2em;
  }

  .payment_image {
    width: 22%;
  }

  .PaymentDetail_person_payment {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 800px) {
  .color-under-line {
    width: 64.5%;
  }

  .orbix-second-block {
    margin-top: -4%;
    width: 48vw;
  }

  .sidebar__main__container {
    position: fixed;
    z-index: 1;
    width: 90%;
    height: 100vh;
  }

  .partal__main__container {
    flex-direction: column;
  }

  .portal__jumbotron__expand {
    justify-content: flex-start;
    margin-top: 2em;
    padding: 0;
    height: auto;
  }

  .svg1 {
    height: 70px;
  }

  .arrow-sign {
    width: 35px;
    position: relative;
    bottom: 10px;
  }

  .orbix-small-block {
    font-size: 10px;
  }

  .orbix-second-block-text-1 {
    font-size: 13px;
  }
}

@media screen and (max-width: 550px) {
  .orbix-first-block {
    align-items: center;
    flex-direction: column;
    width: 85vw;
    padding-bottom: 5%;
  }

  .orbix-landing-main-text {
    font-size: 20px;
    line-height: 25px;
    padding: 10px 0px;
  }

  .orbix-landing-main-text-1 {
    font-size: 18px;
  }

  .orbix-landing-main-text-2 {
    font-size: 12px;
  }

  .svg1 {
    height: 50px;
  }

  .arrow-sign {
    width: 25px;
    position: relative;
    bottom: 10px;
  }

  .orbix-small-block {
    font-size: 7px;
  }

  .orbix-second-block-text-1 {
    font-size: 10px;
  }
}

@media screen and (max-width: 450px) {
  .orbix-landing-main-text {
    font-size: 15px;
    line-height: 25px;
    padding: 10px 0px;
  }

  .orbix-landing-main-text-1 {
    font-size: 11px;
    padding-top: 10px;
  }

  .orbix-landing-main-text-2 {
    font-size: 8px;
  }

  .orbix-small-block {
    font-size: 6px;
  }

  .orbix-second-block-text-1 {
    font-size: 8px;
  }

  .svg1 {
    height: 40px;
  }

  .arrow-sign {
    width: 25px;
    position: relative;
    bottom: 10px;
  }
}


@media (min-width: 1200px) and (max-width: 1384px) {
  .color-under-line {
    width: 60%;
  }
}

@media (min-width: 1384px) and (max-width: 1440px) {
  .color-under-line {
    width: 51%;
  }
}

@media (min-width: 1440px) and (max-width: 1650px) {
  .color-under-line {
    width: 50%;
  }
}

@media (min-width: 1650px) and (max-width:2100px) {
  .color-under-line {
    width: 42%;
    margin-top: -1%;
  }
}

@media (min-width: 2100px) and (max-width:2500px) {
  .color-under-line {
    margin-top: -1%;
    width: 34%;
  }
}

@media (min-width: 2500px) {
  .color-under-line {
    width: 30%;
    margin-top: -1%;
  }
}