.getStartedMainContainer {
    width: 100vw;
    height: 100vh;
    background-color: #F5F9FF;
    overflow: hidden;
    /* Prevent scrolling */
}

.getStartedMainContainerStarted {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    /* Ensure it takes full height */
}

.videotag {
    width: 100%;
    height: 40vh;
    /* Adjust the height as needed */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.orbix_video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensure the video covers the container without stretching */
}

.getStartedParentContainer {
    background-color: white;
    width: 100%;
    border-top-right-radius: 70px;
    border-top-left-radius: 70px;
    padding-top: 30px;
    flex-grow: 1;
    /* Allow it to grow and fill available space */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.getStartedContainer {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.getStartedHeadingContainer {
    color: #2B3674;
    text-align: center;
    font-size: 38px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.76px;
}

.getStartedParaContainer {
    color: #A3AED0;
    margin: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.36px;
}

.getStartedButton {
    width: 140px;
    height: 40px;
    border: none;
    border-radius: 68px;
    background-color: #328BDE;
    color: #FFF;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.36px;
    margin-bottom: 20px;
}

.getStartedButton:hover {
    background-color: #328bdee2;
}

.getStartedBottomPara {
    color: #A3AED0;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.24px;
    position: absolute;
    bottom: 10px;
}

@media (max-width: 768px) {
    .getStartedContainer {
        width: 80%;
    }
}

@media (max-width: 425px) {
    .videotag {
        height: 30vh;
        /* Adjust the height as needed for smaller screens */
    }
}