.signup-container {

  height: 100%;
  min-height: 100vh;
  width: 100vw;
}

.signup-left {
  /* background-image: url("../../assets/signup_bg.svg"); */
  /* background-image: url("../../assets/createAccountBg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1; */
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

.signup-left .bot-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.signup-left h1 {
  font-size: 1.7rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.signup-left p {
  font-size: 0.8rem;
  max-width: 600px;
  text-align: justify;
}

.signup-right {
  flex: 1;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.signup-form {
  padding: 1rem;
  border-radius: 8px;
  width: 100%;
  max-width: 80%;
  text-align: center;
}

.signup-form h2 {
  margin-bottom: 1rem;
  font-size: 1.9rem;
  font-weight: 600;
  text-align: left;
  color: #333;
}

.signup-form p {
  margin-bottom: 2rem;
  color: #666;
  text-align: left;
}

.form-group {
  margin-bottom: 1.5rem;
  text-align: left;
  width: 100%;
  overflow: visible;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 600;
  font-size: 0.9rem;
}

.form-group input:focus {
  border: none;
  outline: 1px solid skyblue;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="password"] {
  width: 97%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 25px;
}

.login_forget_pass {
  color: #2462e7;
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  cursor: pointer;
}

.btn-signup {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 1.5rem;
}

.btn-signup:hover {
  background-color: #0056b3;
}

.login_signup_text {
  display: flex;
  color: gray;
  font-size: 0.9rem;
  margin-bottom: 20px;
}

.login_signup_text span {
  color: #2462e7;
  font-size: 0.9rem;
  margin-left: 5px;
  cursor: pointer;
}



/* new approch css   */

.left-section {
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  min-height: auto;
}

.top-div {

  /* height: 50%; */
  /* flex: 1; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Replace with the actual background color or image */
}

.loginWidth {
  width: 100%;
}

.bottom-div {

  background-image: url("../../assets/bgLoginEllipse.svg");
  background-repeat: no-repeat;
  /* height: 30%; */
  flex: 1;
  background-color: black;
  color: white;
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.robot-image {
  /* max-width: 100%; */
  height: auto;
}

.logo {
  /* max-width: 150px; */
  margin-bottom: 20px;
}

.text {
  font-size: 16px;
}



@media (min-width: 1440px) and (max-width: 1650px) {
  .signUpWidth {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

}

@media (min-width: 1650px) {
  .signUpWidth {
    width: 89.5%;
    margin-left: auto;
    margin-right: auto;
  }

  .eye_icon {
    margin-right: 20px;
  }
}



@media (max-width: 1440px) {}

@media (max-width: 1384px) {}

@media (max-width: 1200px) {
  .login-left {
    /* height: 30vh; */
    width: 110%;
  }
}

@media (max-width: 1024px) {}

@media (max-width: 992px) {}

@media (max-width: 786px) {

  .loginWidth {
    width: 100%;
  }

  .signup-container {
    flex-direction: column;
    background-color: #2462e7;
  }

  .left-section {
    width: 100%;
    height: 100%;
  }

  .login-left {
    min-height: 70vh;
    width: 110%;
  }

  .login-left {
    /* background-size: contain; */
    /* Change to contain for smaller screens */
    /* background-position: top; */
    /* Adjust the position if necessary */
    padding: 1rem;
    /* Adjust padding for smaller screens */
  }


  .signup-left {
    min-height: 70vh;
    width: 110%;
  }

  .signup-left {
    /* background-size: contain; */
    /* Change to contain for smaller screens */
    /* background-position: top; */
    /* Adjust the position if necessary */
    padding: 1rem;
    /* Adjust padding for smaller screens */
  }


  .login-left h1 {
    font-size: 25px;
  }

  .login-left p {
    font-size: 15px;
  }

  .signup-left h1 {
    font-size: 25px;
  }

  .signup-left p {
    font-size: 15px;
  }
}

@media (max-width: 576px) {}

@media (max-width: 450px) {


  .text {
    font-size: 14px;
  }

  .signup-form {
    width: 80%;
  }

  .left-content {
    margin-left: 1rem;
    width: 80%;
  }

  .login-left h1 {
    font-size: 20px;
  }

  .login-left p {
    font-size: 12px;
  }

  .signup-left h1 {
    font-size: 20px;
  }

  .signup-left p {
    font-size: 12px;
  }

  .login__container__form__verify div input {
    margin-right: 1em;
  }

  .otp_controls_text_send_again {
    font-size: 12px;
  }
}


@media (max-width: 320px) {
  .login__container__form__verify div input {
    margin-right: 1em;
  }
}

@media (max-width: 280px) {}