 
  /* //////////////////////Profile /////////////////////// */
  
  .Profile_Navbar{
      background-color: #F5F9FF;
      /* position: absolute; */
      margin-left: auto;
      margin-right: 20px; 
    }
    .profile_navbar_text{
        font-size: 12px;
    color: #8696AE;
}

.profile_main{
    background-color: #F5F9FF;
}
.edit_heading{
    font-size: 20px;
    font-weight: 700;
}
.edit_heading_text{
    font-size: 12px;
    color: #8696AE;
}
.upload_text{
    font-size: 13px;
    font-weight: 600;
}
.upload_detail{
    font-size: 10px;
    font-weight: 500;
    color: #8696AE;
}
.profile_input{
    color: #5a6576;
    border: 1px solid #ebebeb;
    width: 100%;
    padding: 12px 10px;
    border-radius: 20px;
    font-weight: 500;
}
.profile_input:focus{
    /* padding-left: 20px; */
    outline: none;
}
.profile_button{
    /* margin-top: 50px ; */
    font-size: 12px;
    padding: 12px 20px ;
    background-color: #328BDE;
    border-radius: 20px;
    color: white;
}
.security_button{
    font-size: 12px;
    padding: 12px 20px ;
    background-color: #328BDE;
    border-radius: 20px;
    color: white;
}

@media (max-width: 765px) {
    .Profile_Navbar{
         margin-bottom: 5px;
        margin-left: auto;
        margin-right: auto; 
      }     
      .edit_heading{
        font-size: 15px;
    }
    .edit_heading_text{
        font-size: 9px;
        color: #8696AE;
    }
  }
@media (max-width: 370px) {
    .profile_navbar_text{
        font-size: 10px;
        width: 50%;
        height: 28px;

}
    .Profile_Navbar{
        margin-bottom: 5px;
        width: 100px;
        height: 30px;
      }     
      .edit_heading{
        font-size: 15px;
    }
    .edit_heading_text{
        font-size: 9px;
        color: #8696AE;
    }
    .profile_input{
        width: 80%;
        padding: 8px 10px;

    }
    .image_size{
        width: 70px;
        height: 70px;
    }
    .visible_button{
        right: 43px;
    }
    .security_button{
        justify-content: center;
        font-size: 9px;
        padding: 10px 20px ;
        border-radius: 20px;

    }    
  }