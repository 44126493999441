.Simplify_box_in_box {
  height: auto;
  padding-top: 5%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.Simplify_box_in_box_detail {
  width: 35%;
}

.simplify_text {
  color: #8487a8;
  font-size: 15px;
}

.sign_up_free_button {
  background-image: url("../../assets//business-concept.png");
  width: 35%;
  padding: 2.5%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Subscribe_text_email {
  font-weight: 500;
  padding: 3%;
  border: none;
  outline: none;
}

/* .Subscribe-text-email:focus {
    border: none;
    outline: none;
} */

.Subscribe_text_email::placeholder {
  font-weight: 700;
}

.Subscribe_text {
  background-color: white;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 4%;
  padding-right: 1%;
  width: 22vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  border: none;
  color: #8487a8;
}

.arrow_box_1 {
  padding-top: 3%;
  font-weight: 650;
}

.arrow {
  color: #54a9d3;
}

.arrow_text {
  color: black;
}

.sign_up_text {
  padding: 0.8em 1em;
  display: flex;
  align-items: flex-end;
  border-radius: 30px;
  color: white;
  border: none;
  background-color: #54a9d3;
  cursor: pointer;
}

.sign_up_text:hover {
  box-shadow: 4px;
  background-color: rgb(38, 118, 186);
}

.Simplify_box_in_box_detail_1 {
  width: 30%;
}

.Simplify_orbix_text_main {
  width: 25vw;
  font-size: 35px;
  font-weight: 700;
}

.header_image_under_line_simplify {
  margin-top: -6.5%;
  margin-left: 12%;
}

.Simplify_orbix_text_main_detail {
  color: #8487a8;
  margin-top: -5%;
}

.arrow_blox_1 {
  margin-top: 0.8em;
}

.arror {
  font-weight: 500;
  margin-bottom: 0.2em;
}


@media screen and (min-width: 2500px) and (max-width: 4000px) {
  .header_image_under_line_simplify {
    margin-top: -1.5%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1410px) and (max-width: 2500px) {
  .header_image_under_line_3 {
    margin-top: -2.5%;
  }

  .header_image_under_line_simplify {
    margin-top: -1.5%;
    margin-left: 0;
    margin-bottom: 20px;
  }

  .Simplify_orbix_text_main {
    font-size: 31px;
    font-weight: 700;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1409px) {
  .Subscribe_text {
    width: 26vw;
  }

  .header_image_under_line_simplify {
    margin-top: -5%;
    width: 80%;
    margin-left: -1%;
  }

  .Simplify_orbix_text_main {
    width: 42vw;
    /* font-size: 30px; */
  }
}

@media screen and (min-width: 1031px) and (max-width: 1200px) {
  .Simplify_box_in_box_detail img {
    width: 100%;
    height: auto;
  }

  .Subscribe_text {
    width: 28vw;
  }

  .sign_up_free_button {
    width: 37%;
  }

  .header_image_under_line_simplify {
    margin-top: -5%;
    width: 80%;
    margin-left: -1%;
  }

  .Simplify_orbix_text_main {
    width: 42vw;
    /* font-size: 30px; */
  }
}

@media screen and (min-width: 851px) and (max-width: 1030px) {
  .Subscribe_text {
    width: 37vw;
  }

  .header_image_under_line_simplify {
    margin-top: -5%;
    width: 80%;
    margin-left: -1%;
  }

  .Simplify_orbix_text_main {
    width: 42vw;
    /* font-size: 30px; */
  }
}

@media (max-width: 850px) {
  .Simplify_box_in_box {
    flex-direction: column-reverse;
  }

  .Simplify_box_in_box_detail {
    margin: 3%;
    width: 80%;
  }

  .Simplify_main_box {
    display: flex;
    flex-direction: column-reverse;
  }

  .Simplify_orbix_text_main {
    width: 80vw;
    font-size: 30px;
  }

  .Subscribe_text {
    width: 90vw;
  }

  .Simplify_orbix_text_main_detail {
    text-align: justify;
  }

  .Subscribe_text_email {
    padding: 0%;
  }

  .sign_up_free_button {
    width: 100vw;
    padding: 7%;
  }

  .email_box_simplify {
    flex-direction: column;
  }

  .Simplifysvg2 {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .header_image_under_line_simplify {
    margin-top: -5%;
    width: 90%;
    margin-left: -1%;
  }
}

@media screen and (max-width: 600px) {
  .header_image_under_line_simplify {
    margin-top: -5%;
    width: 90%;
    margin-left: -1%;
  }

  .Simplify_box_in_box {
    flex-direction: column;
  }

  .Simplify_box_in_box div {
    /* width: 85%; */
  }

  /* Simplify_box_in_box_detail sign_up_free_button */

  .Simplify_box_in_box_detail {
    width: 95%;
    margin-top: 5%;
  }
}

.sign_up_free_button {
  /* width: 35%; */
  padding: 2.5% 0.2%;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Subscribe_text {
  width: 96%;
  font-size: 0.8rem;
}

.sign_up_text {
  padding: 0.5em 0.6em;
  display: flex;
  align-items: flex-end;
}

.Subscribe_text_email {
  padding-right: 5px;
}