.faq_main_heading{
    font-size: 30px;
    font-weight: 600;
}
.faq_main_heading_text{
    font-size: 13px;
    text-align: center;
}
.faq_Blocks{
    background-color: #FAFAFA;
}
.faq_Blocks__innerText{
    font-size: 10px;
}
.faq_light_text{
    color: #A6A6A6
}
@media (max-width: 415px) {
    .faq_main_heading {
        font-size: 20px;
    }
    .faq_main_heading_text{
        font-size: 10px;
    }
    .question{
        font-size: 11px;
    }
  }