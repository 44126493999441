body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.login-left {
  /* background-image: url("../../assets/waving_hand.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex: 1; */
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  height: 100vh;
}

.img-content {
  height: 200px;
  width: 100%;
}

.left-content {
  height: 50vh;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  /* align-self: flex-start;
  justify-self: flex-end; */
  margin-left: 2rem;
  /* margin-bottom: 2.5rem; */
  width: 84%;
}

.login-left .bot-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

/* .login-left h1 {
  font-size: 1.7rem;
  font-weight: 600;
  margin-bottom: 1rem;

  color: #FFF;

  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.login-left p {
  font-size: 0.9rem;
  max-width: 500px;
  text-align: justify;

  color: rgba(255, 255, 255, 0.81);


  font-style: normal;
  font-weight: 400;
  line-height: 150%;
} */

.eye_icon {
  position: absolute;
  /* background-color: white; */
  cursor: pointer;
  padding: 10px;
  top: 34px;
  right: 23px;
  overflow: hidden;
}


@media (min-width: 1650px) {
  .eye_icon {
    margin-right: 20px;
  }
}