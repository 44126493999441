.prompts_blox {
  padding: 5%;
  background: linear-gradient(to bottom,
      rgba(8, 48, 84, 1),
      rgba(57, 149, 235, 1));
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.prompts_blox_header_text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.prompts_blox_text_1 {
  align-items: center;
  font-size: 30px;
  color: white;
  margin-top: 2%;
  font-weight: 500;
}

.header_image_under_line_prompt {
  margin-top: -2.5%;
}

.header_image_under_line_prompt img {
  width: 100%;
  height: auto;
}

.prompts_bloxs_block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  cursor: pointer;
}

.prompts_bloxs_block_box {
  padding: 3%;
  background-color: white;
  width: 30%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1em;
  border-radius: 10px;
  transform: scale(1);
  transition: transform 0.3s ease;
}

.prompts_bloxs_block_box:hover {
  background: linear-gradient(to bottom,
      rgba(8, 48, 84, 1),
      rgba(57, 149, 235, 1));
  border-radius: 10px;
}

.prompts_icon {
  border-radius: 25px;
  background-color: rgb(55, 146, 231);
  transform: scale(1);
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
}

.prompts_icon img {
  width: 22px;
  height: auto;
}

.prompts_name {
  font-size: 23px;
  margin-left: 4%;
  font-weight: 700;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s ease;
}

.prompts_bloxs_block_box:hover .prompts_name {
  color: white;
  transform: scale(1.1);
}

.prompts_boxes_header {
  display: flex;
}

.prompts_list {
  margin-top: 0.5em;
}

.prompts_list_items_wrapper {
  display: flex;
  align-items: baseline;
  color: rgb(104, 104, 104);
  transform: scale(1);
  transition: transform 0.3s ease;
}

.prompts_bloxs_block_box:hover .prompts_list_items_wrapper {
  color: white;
  transform: scale(1.1);
}

.prompts_list_items {
  width: 90%;
}

.prompt_point_wrapper {
  width: 8%;
}

.prompt_point_wrapper::before {
  content: "•";
  color: rgb(64, 156, 238);
  display: inline-block;
  margin-right: 0.5em;
  vertical-align: sub;
  line-height: 1;
  font-size: 2em;
}

.prompt_more {
  color: #5196d5;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 1em;
  font-weight: 700;
  transform: scale(1);
  transition: transform 0.3s ease;
}

.prompts_bloxs_block_box:hover .prompt_more {
  color: white;
  transform: scale(1.1);
}

.prompt_book {
  color: #5196d5;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 9em;
  font-weight: 700;
  transform: scale(1);
  transition: transform 0.3s ease;
}

.prompts_bloxs_block_box:hover .prompt_book {
  color: white;
  transform: scale(1.1);
}

.prompt_more_and_book {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 2000px) {
  .header_image_under_line_prompt {
    margin-top: 0%;
  }
}

@media screen and (min-width: 1709px) and (max-width: 1921px) {
  .header_image_under_line_prompt {
    margin-top: -1.5%;
  }
}

@media screen and (min-width: 1026px) and (max-width: 1708px) {
  .header_image_under_line_prompt {
    margin-top: -1.5%;
  }
}

@media screen and (min-width: 801px) and (max-width: 1025px) {

  /* .prompts_bloxs_block_box {
    width: 80vw;
  } */
  .header_image_under_line_prompt {
    margin-top: -1.5%;
  }

  .prompts_icon {
    padding-bottom: 2%;
    padding-top: 3%;
    padding-left: 4%;
    padding-right: 4%;
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  .prompts_bloxs_block_box {
    width: 40vw;
  }
}

@media screen and (max-width: 600px) {
  .prompts_bloxs_block {
    flex-direction: column;
    align-items: center;
  }

  .prompts_bloxs_block_box {
    padding: 6%;
    width: 90%;
  }

  .prompts_blox_header_text {
    width: 80vw;
  }

  .prompts_blox_text_1 {
    font-size: 21px;
  }

  .prompts_icon {
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 3%;
    padding-right: 3%;
    border-radius: 21px;
    background-color: rgb(55, 146, 231);
  }
}