.login-left.forget_pass {
  /* background-image: url("../../assets/forgetPassBg.svg"); */
  background-image: url("../../assets/verify_identity.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.forget_pass_icon {
  position: absolute;
  left: 0;
  top: 0;
  color: white;
  background-color: #328bde;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}