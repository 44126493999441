.hearAboutUsMainContainer {
  width: 100vw;
  min-height: 100vh;
  background: #f5f9ff;
  display: flex;
  flex-direction: column;
}

.hearAboutUsMainContainerHearAboutUs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.hearAboutUsContainer {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.hearAboutUsHeadingContainer {
  color: #393939;
  margin-top: 30px;

  text-align: center;
  font-size: 38px;
  font-weight: 500;
  line-height: 32px;
  /* 84.211% */
  letter-spacing: -0.76px;
}

.hearAboutUsParaContainer {
  color: #959595;
  margin: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  letter-spacing: -0.36px;
}

.hearAboutUsBottomPara {
  color: #a3aed0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 200% */
  letter-spacing: -0.24px;
  /* margin-top: 13%; */
  margin-bottom: 30px;
  /* position: absolute;

  bottom: 20px; */
}

/* radio button css  */

.containerHearAboutUs {
  width: 77%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}









@media (max-width: 1650px) {}

@media (max-width: 1440px) {}

@media (max-width: 1384px) {}

@media (max-width: 1200px) {}

@media (max-width: 1024px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {
  .hearAboutUsContainer {
    width: 80%;
  }
}

@media (max-width: 576px) {}

@media (max-width: 480px) {
  .buttonBack {
    height: 43px;
  }
}

@media (max-width: 320px) {
  .buttonBack {
    width: 150px;
  }

  .button {
    font-size: 9px;
    width: 200px;
    height: 43px;
    font-weight: 600;

  }
}

@media (max-width: 280px) {
  .button {
    font-size: 9px;
    width: 200px;
    height: 43px;
    font-weight: 600;

  }
}