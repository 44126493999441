.BlogDetailMainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  padding-bottom: 0%;
}
.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 6px 85px 6px 85px;
  /* margin-top: 10px; */
  position: fixed;
  background-color: white;
  width: 100vw;
  z-index: 999;
}
.LogoStyle {
  height: 35px;
  width: 180px;
}
.getTsartedAndNumberContainer {
  display: flex;
  align-items: center;
}
.TopNumStyle {
  position: relative;
  border: 1px solid black;
  padding: 5px;
  margin-right: 20px;
  font-size: 1.2em;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
}

.TopNumStyle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-image: linear-gradient(to right, #30d663, #30d663);
  transition: width 0.5s ease;
  z-index: -1;
}

.TopNumStyle:hover::before {
  width: 100%;
}
.TopNumStyle:hover {
  color: white;
}

.getStartedButton {
  background-color: #30d663;
  padding: 7px 13px 7px 13px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  margin-right: 15px;
  font-size: 1.2rem;
  text-align: center;
}
.getStartedButton:hover {
  box-shadow: 0 0 25px rgba(48, 214, 99, 0.6);
}
.termsContainer {
  padding-top: 20px;
}

.mainHeading {
  color: rgba(59, 153, 240, 1);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.mainHeading h1 {
  font-size: 2.5rem;
  margin-top: 7%;
}
.mainHeading span {
  color: #1a4048;
}
.text {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}
.text span {
  width: 90%;
  text-align: justify;
  line-height: 1.6em;
}
.textSections {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3%;
}
.textSections div {
  width: 90%;
  text-align: justify;
  line-height: 1.6em;
}
.textSections div h2 {
  color: #1a4048;
  margin: 15px 0;
  margin-top: 29px;
}
.textSections div ul {
  line-height: 2.5em;
  margin-left: 40px;
}
.contactContainer {
  margin-top: 15px;
}
.contactSubContainers {
  margin: 7px 0;
  width: 100%;
  display: flex;
}
.catType {
  font-weight: 600;
  margin-right: 10px;
  width: 130px;
}
.contactInfo {
  flex: 1;
  text-align: left;
}

@media (max-width: 717px) {
  .mainHeading h1 {
    margin-top: 25%;
  }
  .textSections div h2 {
    font-size: 1.2rem;
  }
  .textSections {
    margin-bottom: 20%;
  }
}
