.card__main__container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /* width: 100%;
    height: 100%; */
  z-index: 9999;
  /* background: transparent; */
  background-color: rgba(36, 36, 36, 0.475);
}

.card_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: whitesmoke;
  width: 40%;
  border-radius: 10px;
}
.sub_card_container {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.pay_btn {
  width: auto;
  border-radius: 5px;
  padding: 5px;
  background-color: rgb(51, 141, 224);
  color: white;
  margin-top: 40px;
}
